import React from "react";
import logo from "../../style/images/logo.svg";
import email_icon from "../../style/images/email_icon.svg";
import phone_icon from "../../style/images/phone_icon.svg";
import address_icon from "../../style/images/address_icon.svg";
import { footerTranslate } from "../../utils/translate";
import { useLanguage } from "../../utils/LanguageContext";
import "../../style/footer.css";
const Footer = () => {
  const { languageSlug: lang } = useLanguage();
  return (
    <footer className="footer_main">
      <div className="footer_det">
        <img src={logo} className="footer_det_logo" alt="logo" />
        <span className="footer_text">{footerTranslate.fur[lang]}</span>
      </div>

      <div className="footer_right">
        <div className="footer_det">
          <span className="footer_title">{footerTranslate.address[lang]}</span>
          <div className="footer_det_desc">
            <img src={address_icon} alt="address icon" />
            <span className="footer_text">{footerTranslate.street[lang]}</span>
          </div>
        </div>

        <div className="footer_det">
          <span className="footer_title">{footerTranslate.contact[lang]}</span>
          <div className="footer_det_desc">
            <img src={phone_icon} alt="phone icon" />
            <span className="footer_text">+380 (99) 56 888 29</span>
          </div>
          <div className="footer_det_desc">
            <img src={phone_icon} alt="phone icon" />
            <span className="footer_text">+380 (99) 336 87 59</span>
          </div>
          <div className="footer_det_desc">
            <img src={phone_icon} alt="phone icon" />
            <span className="footer_text">+380 (96) 289 09 37</span>
          </div>
          <div className="footer_det_desc">
            <img src={phone_icon} alt="phone icon" />
            <span className="footer_text">+380 (63) 870 58 78</span>
          </div>
          <div className="footer_det_desc">
            <img src={email_icon} alt="email icon" />
            <span className="footer_text">efimova43@gmail.com</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
